import { Query } from '@directus/types'
import { consola } from 'consola'

export const useAgency = () => {
    const globalSettings = inject('globalSettings')
    const { generateNewsDto, generateNewsCategoryDto } = useNewsUtils(globalSettings)
    const { generateAgencyDto } = useAgencyUtils()
    const { getItems } = useDirectusItems()
    const { calTotalPage } = useParseData()

    const getAgencyIndex = async (options?: { query?: { limit?: any } }) => {
        return getItems({
            collection: 'agencies',
            params: {
                filter: {
                    status: {
                        _eq: 'published'
                    }
                },
                fields: ['id', 'status', 'code', 'title', 'address', 'province', 'district'],
                sort: ['-date_created'],
                limit: +options?.query?.limit || -1
            }
        })
            .then((data) => data.map((item: any) => generateAgencyDto(item)))

            .catch((e) => {
                consola.error('ERR getAgencyIndex : ', e)
                return []
            })
    }

    const filterdAgency = async (options?: { province?: string; district?: string; query?: { limit?: any } }) => {
        let and: any = [
            {
                status: {
                    _eq: 'published'
                }
            }
        ]

        if (options?.province) {
            and.push({
                province: {
                    _icontains: options.province.trim()
                }
            })
        }

        if (options?.district) {
            and.push({
                district: {
                    _icontains: options.district.trim()
                }
            })
        }

        return getItems({
            collection: 'agencies',
            params: {
                filter: {
                    _and: [...and]
                },
                fields: ['id', 'status', 'code', 'title', 'address', 'province', 'district'],
                sort: ['-date_created'],
                limit: +options?.query?.limit || -1
            }
        })
            .then((data) => {
                return data.map((item: any) => generateAgencyDto(item))
            })

            .catch((e) => {
                consola.error('ERR filterdAgency : ', e)
                return []
            })
    }

    const getNewsForAgency = async (options: { query: { limit: any; page: any } }) => {
        return await getItems({
            collection: 'news',
            params: {
                fields: [
                    'id',
                    'status',
                    'tags',
                    'date_created',
                    'category.id',
                    'category.status',
                    'category.title',
                    'category.slug',
                    'title',
                    'for_agency',
                    'intro',
                    'slug',
                    'thumbnail'
                ],
                filter: {
                    _and: [
                        {
                            status: { _eq: 'published' }
                        },
                        { for_agency: { _eq: true } }
                    ]
                },
                limit: options.query.limit || 12,
                page: options.query.page || 1,
                meta: 'filter_count',
                sort: ['hero', 'featured', '-date_created']
            }
        })
            .then((res: any) => {
                return {
                    news: res.data.map((ite: any) => generateNewsDto(ite) as TypeNewsDto),
                    total_page: calTotalPage(+res.meta.filter_count, options.query.limit || 12),
                    total_count: res.meta.filter_count,
                    current_page: options.query.page || 1
                }
            })
            .catch((e: any) => {
                consola.error('Error: getNewsForAgency!')
                consola.debug(e)
                console.log(e)

                return []
            })
    }

    return {
        getAgencyIndex,
        filterdAgency,
        getNewsForAgency
    }
}
