import { consola } from "consola";
import { AgencyTypeEntity, AgencyTypeDto } from "./agency.type"
export const useAgencyUtils = () => {

    const generateAgencyDto = (item: any): AgencyTypeEntity => {

        try {
            const output = {
                ...item,
            };
            return output;

        } catch (e) {
            consola.error("Error: generateAgencyDto!");
            consola.error(e);
            return {};
        }
    }

    return {
        generateAgencyDto
    }
}
